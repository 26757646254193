// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'js-cookie';

import './modules/hamburger';
import './modules/sticky-header';
import './modules/sliders';
import './modules/popups';
import './modules/guide-search';
import './modules/wine';
import './modules/fake-select';
import './modules/mapbox';
import './modules/youtube';

// Ajoute la class pour le bouton submit de search and filter pro
(function ($) {
  $(document).on('sf:ajaxformfinish', '.searchandfilter', function () {
    $('.searchandfilter input[type="submit"]').addClass('btn btn-red');
  });

  $(document).on('sf:ajaxfinish', '.searchandfilter', function () {
    $('.searchandfilter input[type="submit"]').addClass('btn btn-red');
  });

  $('.searchandfilter input[type="submit"]').addClass('btn btn-red');

  //restrict number phone form
  $('input[type="tel"]').on('input', function () {
    $(this).val($(this).val().replace(/[^0-9]/g, ''));
  });

  //restrict number zip form
  $('.zip input[type="text"]').on('input', function () {
    $(this).val($(this).val().replace(/[^0-9]/g, ''));
  });

}(jQuery));
