// const variables
const colorClassName = 'sf-option-active';
const notationTexts = [
  {
    wine: ['rouge'],
    first: { begin: 'Léger', end: 'Puissant' },
    second: { begin: 'Fruité', end: 'Tannique' },
  },
  {
    wine: ['blanc', 'rose'],
    first: { begin: 'Sec', end: 'Doux' },
    second: { begin: 'Minéral', end: 'Fruité' },
  },
];

// the notation sliders
const firstNotation = $('.sf-field-post-meta-_crb_wine_first_notations');
const secondNotation = $('.sf-field-post-meta-_crb_wine_second_notations');

// delete original titles
firstNotation.find('h4').remove();
secondNotation.find('h4').remove();

//create new title container and add them
firstNotation.prepend($('<div>', { 'id': 'first-notation', 'class': 'notation-title d-flex justify-content-between' }));
secondNotation.prepend($('<div>', { 'id': 'second-notation', 'class': 'notation-title d-flex justify-content-between' }));

// original check
setNotations();

$(
  $(document).on('click', '.sf-field-taxonomy-color input', (e) => {
    $(e.target).parent().toggleClass(colorClassName);
    setNotations();
  })
);

// check if color are active for display
function setNotations() {
  // the color currently chosen
  const activeColorList = [...$('.sf-field-taxonomy-color .' + colorClassName)];

  if (activeColorList.length === 0) {
    $(firstNotation).addClass('visually-hidden');
    $(secondNotation).addClass('visually-hidden');
  } else {
    $(firstNotation).removeClass('visually-hidden');
    $(secondNotation).removeClass('visually-hidden');

    const notationsText = getNotationsText(activeColorList);
    setNotationsText(notationsText.first, $('#first-notation'));
    setNotationsText(notationsText.second, $('#second-notation'));
  }
}

//generate the title of the notation
function setNotationsText(notationText, container) {
  container.empty();

  const begin = $('<p>');
  begin.text(notationText.begin);

  const end = $('<p>');
  end.text(notationText.end);

  container.append(begin, end);
}

function getNotationsText(activeColorList) {
  let notation = {
    wine: [],
    first: { begin: '', end: '' },
    second: { begin: '', end: '' },
  };

  activeColorList.forEach(colorInput => {
    const color = $(colorInput).find('.sf-input-checkbox').val();

    notationTexts.forEach(n => {
      if (n.wine.includes(color)) {
        notation = concatenateNotations(notation, n);
      }
    });
  }
  );

  return notation;
}

function concatenateNotations(notationsToShow, notationToAdd) {
  //adds a delimiter between each type of notation
  let delimiter = notationsToShow.first.begin == '' ? '' : ' / ';

  // if this type of notation isn't already present, we add it
  if (!notationsToShow.wine.some(e => notationToAdd.wine.includes(e))) {
    notationsToShow.first.begin += delimiter + notationToAdd.first.begin;
    notationsToShow.first.end += delimiter + notationToAdd.first.end;
    notationsToShow.second.begin += delimiter + notationToAdd.second.begin;
    notationsToShow.second.end += delimiter + notationToAdd.second.end;
    notationsToShow.wine = notationsToShow.wine.concat(notationToAdd.wine);
  }

  return notationsToShow;
}

// Sliders strokes ------------------------------------
$(() => {
  if (firstNotation.length || secondNotation.length){
    addStrokes();

    // the slider (a noUiSlider object) registers it's own listener but we can't set them directly
    // so we need to act on a mouse click of the slider container
    $(document).on('mouseup', '.searchandfilter', () => {getNotationList().forEach(updateActiveStrokes);});
  }
});

function addStrokes() {
  const strokesDatas = firstNotation.children('.sf-meta-range-slider');

  //the number of strokes is the data range divided by the step
  const strokesNumber = ((strokesDatas.data('max') - strokesDatas.data('min')) / Math.max(strokesDatas.data('step'), 1)) + 1;

  const strokeContainer = $('<div>', { class: 'guide-search-stroke-container d-flex justify-content-between' });

  const stroke = $('<span>', { class: 'guide-search-stroke' });

  for (let index = 0; index < strokesNumber; index++) {
    strokeContainer.append(stroke.clone());
  }

  firstNotation.append(strokeContainer.clone());
  secondNotation.append(strokeContainer.clone());
  getNotationList().forEach(initializeActiveStrokes);
}

function getNotationList() {
  return [firstNotation, secondNotation];
}

function initializeActiveStrokes(notation) {
  const slider = notation.children('.sf-meta-range');
  setActiveStrokes(slider, [String(slider.data('start-max'))]);
}

function updateActiveStrokes(notation) {
  const slider = notation.children('.sf-meta-range');
  if (slider.children('.meta-slider')[0]) {
    const noUiSlider = slider.children('.meta-slider')[0].noUiSlider;

    // the second handle since we don't want to touch the first one
    setActiveStrokes(slider, [noUiSlider.get()[1]]);
  }
}

function setActiveStrokes(e, values) {
  // the strokes are the children elements of the sibling of the slider
  const strokes = [...$(e).siblings('.guide-search-stroke-container').children()];

  strokes.forEach((element, index) => {
    // check which stroke is active
    if (values.includes(String(index))) {
      $(element).addClass('guide-search-stroke-active');
    } else {
      $(element).removeClass('guide-search-stroke-active');
    }
  });
}

// slider logic ------------------------------------
$(() => {
  $(document).on('DOMNodeInserted', '.noUi-origin', function(event) {
    const handle = $(event.target);
    if (isLowerHandle(handle)) handle.attr('disabled', 'disabled');
	});

});

function isLowerHandle(handle) {
  return handle.siblings('.noUi-origin').length <=0;
}

