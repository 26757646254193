/* global crb_app */
import mapboxgl from 'mapbox-gl';
import MapBoxLanguage from '@mapbox/mapbox-gl-language';

const mapboxStyle = 'mapbox://styles/mapbox/streets-v12';

// number of retailers to fetch with each request
const FETCH_SIZE = 1000;

mapboxgl.accessToken = crb_app.mapbox_token;

class RetailersMap {
  constructor() {
    this.query = '';
    this.retailers = [];
    this.filteredRetailers = [];
  }

  initializeMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: mapboxStyle,
      center: [
        -0.6272114,
        44.8637104,
      ],
      zoom: 3,
    });

    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(this.styleToLocale());

    this.map.on('load', async () => {
      this.map.addSource('retailers', {
        type: 'geojson',
        data: this.formatRetailer(this.retailers),
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'retailers',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#fbc202',
            100,
            '#fbc202',
            750,
            '#fbc202',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'retailers',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });

      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'retailers',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#fbc202',
          'circle-radius': 12,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });

      // inspect a cluster on click
      this.map.on('click', 'clusters', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        this.map.getSource('retailers').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          }
        );
      });

      // When a click event occurs on a feature in
      // the unclustered-point layer, open a popup at
      // the location of the feature, with
      // description HTML from its properties.
      this.map.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const name = e.features[0].properties.name;
        const address = e.features[0].properties.address;
        const phone = e.features[0].properties.phone;

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `${name}: <p>${address}</p> <p>${phone}</p>`
          ).addTo(this.map);
      });

      this.map.on('mouseenter', 'clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', 'clusters', () => {
        this.map.getCanvas().style.cursor = '';
      });
    });
  }

  renderList() {
    let html = '';
    this.filteredRetailers.forEach(retailer => {
      html += `
        <li id='${retailer.properties.id}' class="listings-store-block">
          <span class="title-store">${retailer.properties.name}</span>
          <p class="address-store">${retailer.properties.address}</p>
          <p class="phone-store">${retailer.properties.phone}</p>
        </li>`;
    });

    $('#listings-store').html(html);

    this.filteredRetailers.forEach(retailer => {
      $(document).on('click', `#${retailer.properties.id}`, () => {
        this.goToPoint(retailer.geometry.coordinates);
      });
    });

    if (this.filteredRetailers.length > 1) {
      $('#store-results').html(`${this.filteredRetailers.length} resultats`);
    } else {
      $('#store-results').html(`${this.filteredRetailers.length} resultat`);
    }
  }

  /**
   * Updates datas used as a source by the map
   */
  updateMap() {
    let source = this.map.getSource('retailers');
    // in case the map isn't initialized for the moment
    if (source) {
      source.setData(this.formatRetailer(this.filteredRetailers));
    }
  }

  /**
   * change the map's language depending on the data-locale set on the #map div
   *
   * @returns {MapBoxLanguage} language control for the map
   */
  styleToLocale() {
    const locale = $('#map').data('locale');
    const language = new MapBoxLanguage({defaultLanguage: locale});
    return language;
  }

  async initialize() {
    this.initializeMap();
    this.initEvents();

    let page = 1;

    //while there are retailers to query for, update the map and the list of retailers
    while(await this.setRetailers(page++)) {
      this.filterRetailers();
      this.updateMap();
      this.renderList();
    }

    // update the map with the last retailers added
    this.filterRetailers();
    this.updateMap();
    this.renderList();

  }

  initEvents() {
    $(document).on('keyup', '#locator-search', (e) => {
      this.query = e.target.value;

      this.filterRetailers();
      this.updateMap();
      this.renderList();
    });
  }

  filterRetailers() {
    if (this.query) {
      this.filteredRetailers = this.retailers.filter(retailer => {
        return retailer.properties.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1 || retailer.properties.address.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
      });
    } else {
      this.filteredRetailers = this.retailers;
    }
  }


  async setRetailers(page= 0) {
    let param = new URLSearchParams({
        size: FETCH_SIZE,
        page: page,
      });
    let rep = await fetch('/wp-admin/admin-ajax.php?action=get_retailers&' + param, { method: 'GET' });
    let response = await rep.json();
    this.retailers.push(...response.features);

    // We can check if there are retailers left from the number of results
    // if our size variable is a perfect multiple of the total number of retailers, we will make a single useless request
    // but it shouldn't cause any problem
    return response.features.length === FETCH_SIZE;
  }

  formatRetailer(toFormat) {
    return {type: 'FeatureCollection', features: toFormat};
  }

  goToPoint(coordinates) {
    this.map.flyTo(
      {
        zoom: 15,
        center: coordinates,
        duration: 4000,
      },
    );
  }
}

(async function () {
  if($('#map').length) {
    const retailer_map = new RetailersMap();
    await retailer_map.initialize();

  }
}(jQuery));

