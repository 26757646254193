const $window = $(window);
const decalTop = $('.container-wine');
const degustationBlock = $('.wine-degustation');

let degustationPosition = () => {
  if (window.matchMedia("(min-width: 992px)").matches) {
    const decalTopHeight = (decalTop.height() + 140) * -1;
    degustationBlock.css('--degustation-decal-desk', decalTopHeight+'px');
  }
};

$window.on('load resize', () => {
	degustationPosition();
});