/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';

/* Slider Home Gammes */
var range_content_swiper = new Swiper('.range-content-swiper', {
  loop: false,
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
});

var range_thumbs_swiper = new Swiper('.range-thumbs-swiper', {
  slidesPerView: 'auto',
  loop: false,
  spaceBetween: 20,
  thumbs: {
    swiper: range_content_swiper,
  },
  breakpoints: {
    992: {
      spaceBetween: 30,
    },
  },
});

range_content_swiper.on("slideChange", () => {
  range_thumbs_swiper.slideTo(range_content_swiper.activeIndex);
});

/* Slider section accordions */
var accordions_swiper = new Swiper('.accordions-swiper', {
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
});

/* Slider section gamme vins */
var wine_swiper = new Swiper(".wine-swiper", {
  slidesPerView: 'auto',
  spaceBetween: 0,
  loop: true,
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
  },
});

/* Slider single wine - others wines */
var single_wine_swiper = new Swiper(".single-wine-swiper", {
  slidesPerView: 'auto',
  spaceBetween: 0,
  loop: true,
  centeredSlides: true,
  breakpoints: {
    400: {
      centeredSlides: false,
    },
    1200: {
      centeredSlides: false,
      slidesPerView: 4,
    },
  },
});
/* eslint-enable no-unused-vars */
