$(function () {
  $(document).on('click', '.fake-options a', (e) => {
    // prevent scrolling
    e.preventDefault();

    const target = $(e.target).closest('a');
    const container = target.parents('.fake-select-container');

    container.find('.fake-select-toggle').click();
    const goTo = container.find('.fake-select-confirm');

    // the base url where the wine type will be added (should be '/guide/?_sft_color=' )
    const baseUrl = goTo.data('base-url') || '';

    goTo.attr('href', baseUrl + target.data('choice'));

    const selectedText = target.contents().filter(function () {
      return this.nodeType === 3;
    }).text().trim();

    container.find('[id^="fake-select-text-"]').text(selectedText);
  });
});
