import Modal from 'bootstrap/js/dist/modal';
import Cookies from 'js-cookie';

// popup variables
const options = {backdrop: 'static', keyboard: false};
const legalAgePopup = new Modal($('#legal-age-popup'), options);

// legal age popup functions
$(document).on('click', '#legal-age-confirm', () => {
  const legalAgeMet = $('#legal-age-met').is(':checked');
  if (legalAgeMet) {
    legalAgePopup.hide();
    Cookies.set('legalAgeMet', legalAgeMet, { expires: 3 });
  }
});

$(document).on('click', '#legal-age-input', () => {
  const legalAgeMet = $('#legal-age-met').is(':checked');
  $('#legal-age-confirm').prop('disabled', !legalAgeMet);
});

if (Cookies.get('legalAgeMet') != "true") {
  legalAgePopup.show();
}
