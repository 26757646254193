// Charge la vidéo du bloc Video de la page d'accueil
// au clic du bouton lecture
$(document).ready(function() {
    $('#video-player').on('click', function(ev) {
      let iframe = '';
      if($('#uby-player').length) {
        iframe = document.getElementById('uby-player');
      } else if($('#uby-achievement-player').length) {
        iframe = document.getElementById('uby-achievement-player');
      }
      let videoUrl = iframe.getAttribute('data-src');
      iframe.setAttribute('src', videoUrl + '?rel=0&controls=1&frameborder=0&showinfo=0&autoplay=1&mute=1');
      ev.preventDefault();
      document.getElementById("uby-achievement-player").style.zIndex = "2";
    });
  });
